import messages from '../../../../utils/text-resources/sambla/SE/messages';

const PROPERTY_TYPES = [
  {
    label: messages.mortgagePropertyTypeCondo,
    value: 'condo',
  },
  {
    label: messages.mortgagePropertyTypeVilla,
    value: 'villa',
  },
  {
    label: messages.mortgagePropertyTypeCottage,
    value: 'cottage',
  },
];

export default PROPERTY_TYPES;
