import textResource from '../../../../utils/text-resources/sambla/SE/messages';

const EMPLOYMENT_TYPES = [
  {
    label: textResource.employmentTypePermanent,
    value: 'permanent',
  },
  {
    label: textResource.employmentTypeProbationary,
    value: 'probationary',
  },
  {
    label: textResource.employmentTypeRetired,
    value: 'retired',
  },
  {
    label: textResource.employmentTypeDisabilityPension,
    value: 'disabilityPension',
  },
  {
    label: textResource.employmentTypeProject,
    value: 'project',
  },
  {
    label: textResource.employmentTypeByTheHour,
    value: 'byTheHour',
  },
  {
    label: textResource.employmentTypeEmployedAtOwnCompany,
    value: 'employedAtOwnCompany',
  },
];

export default EMPLOYMENT_TYPES;
