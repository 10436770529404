const employmentType = {
  UNTIL_FURTHER_NOTICE_PUBLIC: 'UNTIL_FURTHER_NOTICE_PUBLIC',
  UNTIL_FURTHER_NOTICE_PRIVATE: 'UNTIL_FURTHER_NOTICE_PRIVATE',
  UNTIL_FURTHER_NOTICE: 'UNTIL_FURTHER_NOTICE',
  PROBATIONARY: 'PROBATIONARY',
  TEMPORARY: 'TEMPORARY',
  OWN_COMPANY: 'OWN_COMPANY',
  SELF_EMPLOYED: 'SELF_EMPLOYED',
  RETIRED: 'RETIRED',
  BY_THE_HOUR: 'BY_THE_HOUR',
  DISABILITY_PENSION: 'DISABILITY_PENSION',
  PARENTAL_LEAVE: 'PARENTAL_LEAVE',
  STUDENT: 'STUDENT',
  UNEMPLOYED: 'UNEMPLOYED',
  PART_TIME: 'PART_TIME',
  REHABILITATION: 'REHABILITATION',
  OTHER: 'OTHER',
};

const civilStatus = {
  SINGLE: 'SINGLE',
  PARTNER: 'PARTNER',
  MARRIED: 'MARRIED',
  WIDOW: 'WIDOW',
  DIVORCED: 'DIVORCED',
};

const homeType = {
  RENTED_APARTMENT: 'RENTED_APARTMENT',
  VILLA: 'VILLA',
  CONDOMINIUM: 'CONDOMINIUM',
  RENTED_ROOM: 'RENTED_ROOM',
  OTHER: 'OTHER',
  PARENTS: 'PARENTS',
  OWNED_APARTMENT: 'OWNED_APARTMENT',
};

const objectHomeType = {
  VILLA: 'VILLA',
  CONDOMINIUM: 'CONDOMINIUM',
  HOLIDAY_HOME: 'HOLIDAY_HOME',
};

const purpose = {
  COMBINING_LOANS: 'COMBINING_LOANS',
  EVERYDAY_EXPENSES: 'EVERYDAY_EXPENSES',
  HOME: 'HOME',
  VEHICLE: 'VEHICLE',
  TRAVEL: 'TRAVEL',
  OTHER: 'OTHER',
  HEALTH: 'HEALTH',
  INVESTMENT: 'INVESTMENT',
  SERVICES: 'SERVICES',
  STUDIES: 'STUDIES',
  RENOVATION: 'RENOVATION',
  BUSINESS: 'BUSINESS',
  PROPERTY_PURCHASE: 'PROPERTY_PURCHASE',
};

const educationLevel = {
  PRIMARY_SCHOOL: 'PRIMARY_SCHOOL',
  HIGH_SCHOOL: 'HIGH_SCHOOL',
  COLLEGE: 'COLLEGE',
  BACHELOR: 'BACHELOR',
  MASTERS: 'MASTERS',
};

const citizenship = {
  FINNISH: 'FINNISH',
  OTHER: 'OTHER',
  SWEDISH: 'SWEDISH',
  RUSSIAN: 'RUSSIAN',
  ESTONIAN: 'ESTONIAN',
};

const occupationCategory = {
  LOWER_EMPLOYEE: 'LOWER_EMPLOYEE',
  EMPLOYEE: 'EMPLOYEE',
  SENIOR_CLERK: 'SENIOR_CLERK',
  LEADERSHIP: 'LEADERSHIP',
  FARMER: 'FARMER',
  ENTREPRENEUR: 'ENTREPRENEUR',
  STUDENT: 'STUDENT',
  PENSIONER: 'PENSIONER',
  OTHER: 'OTHER',
};

const militaryServiceStatus = {
  SUCCESS: 'SUCCESS',
  PERFORMING: 'PERFORMING',
  EXEMPT: 'EXEMPT',
  NOT_A_MILITARY_SERVANT: 'NOT_A_MILITARY_SERVANT',
};

const currentLoanType = {
  CAR: 'CAR',
  CREDIT_CARD: 'CREDIT_CARD',
  STUDENT: 'STUDENT',
  CONSUMER: 'CONSUMER',
  MORTGAGE: 'MORTGAGE',
  OTHER: 'OTHER',
  INSTALLMENT: 'INSTALLMENT',
};

const taskName = {
  VALIDATE_PEP: 'validatePoliticallyExposedPerson',
  SCRAPE_BANK_ACCOUNT: 'scrapeBankAccount',
  OPTIMIZE_APPLICATION: 'optimizeApplication',
};

const homeTypeCategory = {
  BLOCK_OF_FLATS: 'BLOCK_OF_FLATS',
  TERRACED_HOUSE: 'TERRACED_HOUSE',
  TOWN_HOUSE: 'TOWN_HOUSE',
  DETACHED_HOUSE: 'DETACHED_HOUSE',
  OTHER: 'OTHER',
};

const employmentIndustry = {
  AGRICULTURE_FISH_AND_GAME_FARMING: 'AGRICULTURE_FISH_AND_GAME_FARMING',
  FORESTRY: 'FORESTRY',
  MINING_AND_QUARRYING: 'MINING_AND_QUARRYING',
  INDUSTRIAL: 'INDUSTRIAL',
  ENERGY_AND_WATER_SUPPLY: 'ENERGY_AND_WATER_SUPPLY',
  CONSTRUCTION: 'CONSTRUCTION',
  TRADE: 'TRADE',
  ACCOMMODATION_ACTIVITIES: 'ACCOMMODATION_ACTIVITIES',
  NUTRITION_ACTIVITIES: 'NUTRITION_ACTIVITIES',
  TRANSPORT: 'TRANSPORT',
  COMMUNICATION: 'COMMUNICATION',
  FINANCIAL_AND_INSURANCE_ACTIVITIES: 'FINANCIAL_AND_INSURANCE_ACTIVITIES',
  REAL_ESTATE_AND_RENTAL_SERVICES: 'REAL_ESTATE_AND_RENTAL_SERVICES',
  TECHNICAL_SERVICE_AND_SERVICE_FOR_BUSINESS: 'TECHNICAL_SERVICE_AND_SERVICE_FOR_BUSINESS',
  PUBLIC_ADMINISTRATION: 'PUBLIC_ADMINISTRATION',
  NATIONAL_DEFENCE: 'NATIONAL_DEFENCE',
  PROTECTION_POLICE_AND_FIRE_DEPARTMENT: 'PROTECTION_POLICE_AND_FIRE_DEPARTMENT',
  TRAINING: 'TRAINING',
  RESEARCH: 'RESEARCH',
  HEALTH_SERVICES: 'HEALTH_SERVICES',
  SOCIAL_SERVICES: 'SOCIAL_SERVICES',
  RECREATIONAL_AND_CULTURAL_SERVICES: 'RECREATIONAL_AND_CULTURAL_SERVICES',
  RELIGIOUS_ACTIVITIES: 'RELIGIOUS_ACTIVITIES',
  OTHER_SERVICES: 'OTHER_SERVICES',
  CLEANING_AND_LAUNDRY_ACTIVITIES: 'CLEANING_AND_LAUNDRY_ACTIVITIES',
  ORGANIZATIONAL_ACTIVITIES: 'ORGANIZATIONAL_ACTIVITIES',
  PENSIONER: 'PENSIONER',
  STUDENT_UNEMPLOYED_HOUSEWIFE: 'STUDENT_UNEMPLOYED_HOUSEWIFE',
  UNKNOWN: 'UNKNOWN',
};

const propertyType = {
  condo: 'condo',
  villa: 'villa',
  cottage: 'cottage',
  townhouse: 'townhouse',
  semi_detached_house: 'semi_detached_house',
  other: 'other',
};

module.exports = {
  employmentType,
  civilStatus,
  homeType,
  objectHomeType,
  purpose,
  educationLevel,
  occupationCategory,
  citizenship,
  militaryServiceStatus,
  currentLoanType,
  taskName,
  homeTypeCategory,
  employmentIndustry,
  propertyType,
};
