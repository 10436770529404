const shared = require('./shared');

const employmentType = {
  untilFurtherNoticePrivate: shared.employmentType.UNTIL_FURTHER_NOTICE_PRIVATE,
  untilFurtherNoticePublic: shared.employmentType.UNTIL_FURTHER_NOTICE_PUBLIC,
  untilFurtherNotice: shared.employmentType.UNTIL_FURTHER_NOTICE,
  permanent: shared.employmentType.UNTIL_FURTHER_NOTICE,
  probationary: shared.employmentType.PROBATIONARY,
  retired: shared.employmentType.RETIRED,
  disabilityPension: shared.employmentType.DISABILITY_PENSION,
  temporary: shared.employmentType.TEMPORARY,
  project: shared.employmentType.TEMPORARY,
  byTheHour: shared.employmentType.BY_THE_HOUR,
  employedAtOwnCompany: shared.employmentType.OWN_COMPANY,
  selfEmployed: shared.employmentType.SELF_EMPLOYED,
  soleProprietorship: shared.employmentType.SELF_EMPLOYED,
  unemployed: shared.employmentType.UNEMPLOYED,
  rehabilitation: shared.employmentType.REHABILITATION,
  parentalLeave: shared.employmentType.PARENTAL_LEAVE,
  student: shared.employmentType.STUDENT,
  partTime: shared.employmentType.PART_TIME,
  other: shared.employmentType.OTHER,
};

const civilStatus = {
  single: shared.civilStatus.SINGLE,
  partner: shared.civilStatus.PARTNER,
  married: shared.civilStatus.MARRIED,
  widow: shared.civilStatus.WIDOW,
  divorced: shared.civilStatus.DIVORCED,
};

const homeType = {
  rentedApartment: shared.homeType.RENTED_APARTMENT,
  rentingApartment: shared.homeType.RENTED_APARTMENT,
  villa: shared.homeType.VILLA,
  houseOrTownhouse: shared.homeType.VILLA,
  condominium: shared.homeType.CONDOMINIUM,
  parents: shared.homeType.PARENTS,
  ownedApartment: shared.homeType.OWNED_APARTMENT,
  rentingRoom: shared.homeType.RENTED_ROOM,
  other: shared.homeType.OTHER,
};

const purpose = {
  consolidateLoans: shared.purpose.COMBINING_LOANS,
  everydayExpenses: shared.purpose.EVERYDAY_EXPENSES,
  consumption: shared.purpose.EVERYDAY_EXPENSES,
  home: shared.purpose.HOME,
  vehicle: shared.purpose.VEHICLE,
  vehicles: shared.purpose.VEHICLE,
  travel: shared.purpose.TRAVEL,
  renovation: shared.purpose.RENOVATION,
  business: shared.purpose.BUSINESS,
  health: shared.purpose.HEALTH,
  services: shared.purpose.SERVICES,
  investment: shared.purpose.INVESTMENT,
  buffer: shared.purpose.INVESTMENT,
  studies: shared.purpose.STUDIES,
  propertyPurchase: shared.purpose.PROPERTY_PURCHASE,
  other: shared.purpose.OTHER,
};

const educationLevel = {
  primarySchool: shared.educationLevel.PRIMARY_SCHOOL,
  highSchool: shared.educationLevel.HIGH_SCHOOL,
  bachelor: shared.educationLevel.BACHELOR,
  masters: shared.educationLevel.MASTERS,
  college: shared.educationLevel.COLLEGE,
};

const currentLoanType = {
  car: shared.currentLoanType.CAR,
  student: shared.currentLoanType.STUDENT,
  mortgage: shared.currentLoanType.MORTGAGE,
  creditCard: shared.currentLoanType.CREDIT_CARD,
  consumer: shared.currentLoanType.CONSUMER,
  installment: shared.currentLoanType.INSTALLMENT,
  other: shared.currentLoanType.OTHER,
};

const citizenship = {
  finnish: shared.citizenship.FINNISH,
  other: shared.citizenship.OTHER,
  swedish: shared.citizenship.SWEDISH,
  russian: shared.citizenship.RUSSIAN,
  estonian: shared.citizenship.ESTONIAN,
};

const occupationCategory = {
  lowerEmployee: shared.occupationCategory.LOWER_EMPLOYEE,
  employee: shared.occupationCategory.EMPLOYEE,
  seniorClerk: shared.occupationCategory.SENIOR_CLERK,
  leadership: shared.occupationCategory.LEADERSHIP,
  farmer: shared.occupationCategory.FARMER,
  entrepreneur: shared.occupationCategory.ENTREPRENEUR,
  student: shared.occupationCategory.STUDENT,
  pensioner: shared.occupationCategory.PENSIONER,
  other: shared.occupationCategory.OTHER,
};

const militaryServiceStatus = {
  success: shared.militaryServiceStatus.SUCCESS,
  performing: shared.militaryServiceStatus.PERFORMING,
  exempt: shared.militaryServiceStatus.EXEMPT,
  notAMilitaryServant: shared.militaryServiceStatus.NOT_A_MILITARY_SERVANT,
};

const homeTypeCategory = {
  blockOfFlats: shared.homeTypeCategory.BLOCK_OF_FLATS,
  terracedHouse: shared.homeTypeCategory.TERRACED_HOUSE,
  townHouse: shared.homeTypeCategory.TOWN_HOUSE,
  detachedHouse: shared.homeTypeCategory.DETACHED_HOUSE,
  other: shared.homeTypeCategory.OTHER,
};

const employmentIndustry = {
  agricultureFishAndGameFarming: shared.employmentIndustry.AGRICULTURE_FISH_AND_GAME_FARMING,
  forestry: shared.employmentIndustry.FORESTRY,
  miningAndQuarrying: shared.employmentIndustry.MINING_AND_QUARRYING,
  industrial: shared.employmentIndustry.INDUSTRIAL,
  energyAndWaterSupply: shared.employmentIndustry.ENERGY_AND_WATER_SUPPLY,
  construction: shared.employmentIndustry.CONSTRUCTION,
  trade: shared.employmentIndustry.TRADE,
  accommodationActivities: shared.employmentIndustry.ACCOMMODATION_ACTIVITIES,
  nutritionActivities: shared.employmentIndustry.NUTRITION_ACTIVITIES,
  transport: shared.employmentIndustry.TRANSPORT,
  communication: shared.employmentIndustry.COMMUNICATION,
  financialAndInsuranceActivites: shared.employmentIndustry.FINANCIAL_AND_INSURANCE_ACTIVITIES,
  realEstateAndRentalServices: shared.employmentIndustry.REAL_ESTATE_AND_RENTAL_SERVICES,
  technicalServiceAndServiceForBusiness:
    shared.employmentIndustry.TECHNICAL_SERVICE_AND_SERVICE_FOR_BUSINESS,
  publicAdministration: shared.employmentIndustry.PUBLIC_ADMINISTRATION,
  nationalDefence: shared.employmentIndustry.NATIONAL_DEFENCE,
  protectionPoliceAndFireDepartment:
    shared.employmentIndustry.PROTECTION_POLICE_AND_FIRE_DEPARTMENT,
  training: shared.employmentIndustry.TRAINING,
  research: shared.employmentIndustry.RESEARCH,
  healthServices: shared.employmentIndustry.HEALTH_SERVICES,
  socialServices: shared.employmentIndustry.SOCIAL_SERVICES,
  recreationalAndCulturalServices: shared.employmentIndustry.RECREATIONAL_AND_CULTURAL_SERVICES,
  religiousActivities: shared.employmentIndustry.RELIGIOUS_ACTIVITIES,
  otherServices: shared.employmentIndustry.OTHER_SERVICES,
  cleaningAndLaundryActivities: shared.employmentIndustry.CLEANING_AND_LAUNDRY_ACTIVITIES,
  organizationalActivities: shared.employmentIndustry.ORGANIZATIONAL_ACTIVITIES,
  pensioner: shared.employmentIndustry.PENSIONER,
  studentUnemployedHousewife: shared.employmentIndustry.STUDENT_UNEMPLOYED_HOUSEWIFE,
  unknown: shared.employmentIndustry.UNKNOWN,
};

const objectHomeType = {
  villa: shared.objectHomeType.VILLA,
  condominium: shared.objectHomeType.CONDOMINIUM,
  holidayHome: shared.objectHomeType.HOLIDAY_HOME,
};

const propertyType = {
  condo: shared.propertyType.condo,
  villa: shared.propertyType.villa,
  cottage: shared.propertyType.cottage,
  townhouse: shared.propertyType.townhouse,
  semi_detached_house: shared.propertyType.semi_detached_house,
  other: shared.propertyType.other,
};

module.exports = {
  employmentType,
  civilStatus,
  homeType,
  purpose,
  educationLevel,
  occupationCategory,
  citizenship,
  militaryServiceStatus,
  currentLoanType,
  homeTypeCategory,
  employmentIndustry,
  objectHomeType,
  propertyType,
};
