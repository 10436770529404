import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import {
  submit as submitApplicant,
  consume as consumeApplicant,
  submitCoApplicant,
} from './applicant';

const calculateNewLoan = (
  application,
) => {
  const totalAmount = parseInt(stripDelimiter(application.totalAmount), 10);
  const consolidationAmount = parseInt(stripDelimiter(application.consolidationAmount), 10);

  let newLoanAmount;
  const onlyNewLoan = (!application.merge);

  if ((consolidationAmount === totalAmount) && application.merge) {
    newLoanAmount = 0;
  } else if (onlyNewLoan) {
    newLoanAmount = totalAmount;
  } else {
    newLoanAmount = totalAmount - consolidationAmount;
  }

  return newLoanAmount;
};

const definePurpose = (
  application,

) => {
  const totalAmount = parseInt(stripDelimiter(application.totalAmount), 10);
  const consolidationAmount = parseInt(stripDelimiter(application.consolidationAmount), 10);
  if ((application.merge)
    && (consolidationAmount === totalAmount)) {
    return 'consolidateLoans';
  } if (application.merge
    && (application.consolidationAmount > application.totalAmount)
    && (calculateNewLoan(application) < 5000)) {
    return 'consumption';
  }
  return application.purpose;
};

export const submit = (
  application,
  visibleFields,
) => ({
  inputSource: application.source,
  newLoan: calculateNewLoan(application),
  purpose: definePurpose(application),
  repaymentTime: parseInt(application.repaymentTime, 10) * 12,
  totalAmount: parseInt(stripDelimiter(application.totalAmount), 10),
  applicant: application.applicant
    ? submitApplicant(application.applicant, visibleFields)
    : null,
  coApplicant: application.coApplicant
    ? submitCoApplicant(application.coApplicant, visibleFields)
    : null,
  acceptNewsletter: application.acceptNewsletter,
});

export const consume = (application) => ({
  ...application,
  applicant: application.applicant
    ? consumeApplicant(application.applicant)
    : null,
  coApplicant: application.coApplicant
    ? consumeApplicant(application.coApplicant)
    : null,
  acceptNewsletter: application.acceptNewsletter,
});

export default submit;
