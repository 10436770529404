import messages from '../../../../utils/messages';

const CARS = [
  {
    label: messages.mortgageCarsZero,
    value: '0',
  },
  {
    label: messages.mortgageCarsOne,
    value: '1',
  },
  {
    label: messages.mortgageCarsTwo,
    value: '2',
  },
  {
    label: messages.mortgageCarsThree,
    value: '3',
  },
  {
    label: messages.mortgageCarsFour,
    value: '4',
  },
  {
    label: messages.mortgageCarsFive,
    value: '5',
  },
];
export default CARS;
