// internal
import { phone } from '@advisa/common-validators';

import { region } from '../../localizations/current-locale';
import { valid, invalid } from '../../utils/validators/resolutions';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';

import { dynamicBrand } from '../../utils/brand';

// eslint-disable-next-line import/no-dynamic-require
let messages;
import(`../../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validatePhoneNumber = (input = '', ...props) => {
  if (!/\S+/.test(input || '')) {
    return (props[2] === 'applicant.phoneNumber'
      ? invalid(messages.phoneNumberRequired)
      : invalid(messages.coApplicantPhoneNumberRequired));
  }
  const normalizedInput = normalizePhoneNumber(input);

  const validatedPhone = phone.parse(normalizedInput, region);
  // Common validation check
  if (validatedPhone.valid === 'invalid') {
    return invalid(messages?.phoneNumberWrongNumber);
  }

  if (!/^((00|\+)46|0)/.test(normalizedInput)) {
    return invalid(messages?.phoneNumberWrongBeginning);
  }

  if (/^\+?\d{0,7}$/.test(normalizedInput)) {
    return invalid(messages?.phoneNumberTooShort);
  }

  if (normalizedInput.length > 17) {
    return invalid(messages?.phoneNumberTooLong);
  }

  if (!/^((00|\+)?46|0)7[023469]\d{7}$/.test(normalizedInput)) {
    return invalid(messages?.phoneNumberWrongNumber);
  }

  return valid();
};

export default validatePhoneNumber;
