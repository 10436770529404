export default {
  titleFontSize: '1.4rem',
  subtitleColor: 'var(--black)',
  subtitleFontSize: '1.3rem',
  subtitleFontWeight: 'bold',
  formTitleColor: 'var(--dark-teal)',
  formTitlePadding: '1.6rem 1rem 0',
  subtitlePadding: '1rem',
  mobileTitleAlign: 'center',
  desktopTitleFontWeight: 'bold',
  userAgreementDataPolicyPadding: '0.5rem',
};
