const LOAN_RESTRICTIONS = {
  MIN_AGE: 18,
  MAX_AGE: 99,
  LOAN_MIN_YEARS: 1,
  LOAN_MAX_YEARS: 20,
  MIN_SALARY: 8400,
  MIN_HOME_COST: 0,
  MAX_HOME_COST: 20000,
  MIN_FEES_COST: 0,
  MAX_FEES_COST: 1000,
};

export default LOAN_RESTRICTIONS;
