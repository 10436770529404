import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';

import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import { normalizePhoneNumber } from '../../utils/normalize-phone-number';
import {
  isEmployed,
  isTempEmployed,
  isSelfEmployed,
} from './applicantFunctions';

export const submit = (applicant, visibleFields) => ({
  civilStatus: applicant.civilStatus || null,
  dependants: String(parseInt(applicant.dependants, 10)) || 0,
  email: applicant.email || null,
  employerName: visibleFields.applicant.employerName
    ? ((isEmployed(applicant) && applicant.employerName) || null) : null,
  employerPhone: (
    isEmployed(applicant)
    && !isSelfEmployed(applicant)
    && normalizePhoneNumber(applicant.employerPhone)
  )
    || (isSelfEmployed(applicant) && applicant.phoneNumber)
    || null,
  employerPhoneCountry:
    (isEmployed(applicant) && !isSelfEmployed(applicant) && applicant.employerPhoneCountry)
    || null,
  employmentSince: applicant.employmentSince
    ? format(
      new Date(applicant.employmentSince,
        (visibleFields.applicant.employmentSinceMonth
          ? (applicant.employmentSinceMonth || 0) : 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentTo: visibleFields.applicant.employmentTo
    && (isTempEmployed(applicant)) && applicant.employmentTo
    ? format(
      new Date(applicant.employmentTo, applicant.employmentToMonth),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentType: applicant.employmentType || null,
  homeCost: String(parseInt(stripDelimiter(applicant.homeCost), 10)) || null,
  homeType: applicant.homeType || null,
  monthlySalary: String(parseInt(stripDelimiter(applicant.monthlySalary), 10)) || null,
  nationalId: applicant.nationalId || null,
  phoneNumber: normalizePhoneNumber(applicant.phoneNumber) || null,
});

export const submitCoApplicant = (applicant, visibleFields) => ({
  civilStatus: applicant.civilStatus || null,
  dependants: String(parseInt(applicant.dependants, 10)) || 0,
  email: applicant.email || null,
  employerName: visibleFields.coApplicant.employerName
    ? ((isEmployed(applicant) && applicant.employerName) || null) : null,
  employerPhone: (
    isEmployed(applicant)
    && !isSelfEmployed(applicant)
    && normalizePhoneNumber(applicant.employerPhone)
  )
    || (isSelfEmployed(applicant) && applicant.phoneNumber)
    || null,
  employerPhoneCountry:
    (isEmployed(applicant) && !isSelfEmployed(applicant) && applicant.employerPhoneCountry)
    || null,
  employmentSince: applicant.employmentSince
    ? format(
      new Date(applicant.employmentSince, (visibleFields.coApplicant.employmentSinceMonth
        ? (applicant.employmentSinceMonth || 0) : 0)),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentTo: visibleFields.coApplicant.employmentTo
    && (isTempEmployed(applicant)) && applicant.employmentTo
    ? format(
      new Date(applicant.employmentTo, applicant.employmentToMonth),
      'yyyy-MM-dd\'T\'HH:mm:ss',
    )
    : null,
  employmentType: applicant.employmentType || null,
  homeCost: String(parseInt(stripDelimiter(applicant.homeCost), 10)) || null,
  homeType: applicant.homeType || null,
  monthlySalary: String(parseInt(stripDelimiter(applicant.monthlySalary), 10)) || null,
  nationalId: applicant.nationalId || null,
  phoneNumber: normalizePhoneNumber(applicant.phoneNumber) || null,
});

export const consume = (applicant) => {
  const employmentSince = applicant?.employmentSince ? new Date(applicant.employmentSince) : false;
  const employmentTo = applicant?.employmentTo ? new Date(applicant?.employmentTo) : false;

  return {
    ...applicant,
    employmentSince: String(getYear(employmentSince) || ''),
    employmentSinceMonth: String(getMonth(employmentSince) || ''),
    employmentTo: String(getYear(employmentTo) || ''),
    employmentToMonth: String(getMonth(employmentTo) || ''),
  };
};
