export const isEmployed = (applicant) => [
  'permanent',
  'probationary',
  'project',
  'byTheHour',
  'employedAtOwnCompany',
].includes(applicant.employmentType);

export const isTempEmployed = (applicant) => applicant.employmentType === 'project';

export const isSelfEmployed = (applicant) => [
  'employedAtOwnCompany',
].includes(applicant.employmentType);
