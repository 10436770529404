const { currentLocale } = require('../../../current-locale');

const language = currentLocale.lang;
const { region } = currentLocale;

const locale = `${language}-${region}`;
const { title, brand } = currentLocale;

const DETAILS = {
  WEBSITE_TITLE: title,
  LOCALE: locale,
  NUM_OF_CREDITORS: 43,
  APPLICATION_EXPIRATION_DAYS: 30,
  BANK_ACCOUNT_PLACEHOLDER: '9876-0123456',
  CURRENCY: 'kr',
  LANGUAGE: language,
  REGION: region,
  BRAND: brand,
};

export default DETAILS;
