export default {
  selectedButtonBackground: 'var(--white)',
  selectedBorderColor: 'var(--teal)',
  nonSelectedBackgroundColor: '#f5f5f5',
  toggleTabletLayout: 'repeat(2, 1fr)',
  boxShadow: '0 3px 5px 0 rgb(0 0 0 / 10%)',
  labelWidth: '90%',
  buttonGroupFontWeight: '500',
  checkMarkColor: 'var(--teal)',
};
