import messages from '../../../../utils/messages';

const CREDITOR_NAMES = [
  { label: '', value: null },
  { label: 'Avanza', value: 'Avanza' },
  { label: 'Bluestep', value: 'Bluestep' },
  { label: 'Danske Bank', value: 'Danske Bank' },
  { label: 'Handelsbanken', value: 'Handelsbanken' },
  { label: 'Hypoteket', value: 'Hypoteket' },
  { label: 'ICA Banken', value: 'ICA Banken' },
  { label: 'Ikano Bank', value: 'Ikano Bank' },
  { label: 'Länsförsäkringar', value: 'Länsförsäkringar' },
  { label: 'Marginalen Bank', value: 'Marginalen Bank' },
  { label: 'Nordax', value: 'Nordax' },
  { label: 'Nordea', value: 'Nordea' },
  { label: 'Nordnet', value: 'Nordnet' },
  { label: 'SBAB', value: 'SBAB' },
  { label: 'SEB', value: 'SEB' },
  { label: 'Skandiabanken', value: 'Skandiabanken' },
  { label: 'Sparbanken Syd', value: 'Sparbanken Syd' },
  { label: 'Sparbankerna', value: 'Sparbankerna' },
  { label: 'Stabelo', value: 'Stabelo' },
  { label: 'Svea Ekonomi', value: 'Svea Ekonomi' },
  { label: 'Swedbank', value: 'Swedbank' },
  { label: 'Ålandsbanken', value: 'Ålandsbanken' },
  { label: messages.mortgageCreditorNameOther, value: 'other' },
];

export default CREDITOR_NAMES;
