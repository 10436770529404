const MERGE_LOAN_FORM_DEFAULT_VALUES = {
  totalAmount: 310000,
  repaymentTime: 12,
  merge: null,
  administrationFee: 0,
  consolidationAmount: 310000,
  applicant: {
    dependants: '0',
  },
  coApplicant: {
    dependants: '0',
  },
  loanType: 'consolidation',
  acceptNewsletter: false,
};

const LOAN_CALCULATOR_DEFAULT_VALUES = {
  totalAmount: '150000',
  interestRate: 6.56,
  repaymentTime: 5,
  setUpFee: '300',
  aviaFee: '25',
};

const MORTGAGE_FORM_DEFAULT_VALUES = {
  applicant: {},
  coApplicant: {},
  property: {},
  propertyCurrentLoans: {},
  cars: {},
};

export {
  MERGE_LOAN_FORM_DEFAULT_VALUES,
  LOAN_CALCULATOR_DEFAULT_VALUES,
  MORTGAGE_FORM_DEFAULT_VALUES,
};
