import FormTypes from '../../../../../libs/constants/form-types';
import { MORTGAGE_LOAN_PURPOSES } from '../../../../localizations/config/SE/sambla/loan-purposes';
import EMPLOYMENT_TYPES from '../../../../localizations/config/SE/sambla/employment-types';
import MARITAL_STATUS from '../../../../localizations/config/SE/sambla/marital-status';
import DEPENDANTS from '../../../../localizations/config/SE/sambla/dependants';
import HOME_TYPES from '../../../../localizations/config/SE/sambla/home-types';
import CARS from '../../../../localizations/config/SE/shared/cars';
import PROPERTY_TYPES from '../../../../localizations/config/SE/sambla/property-types';
import CREDITOR_NAMES from '../../../../localizations/config/SE/shared/creditor-names';
import MUNICIPALITIES from '../../../../localizations/config/SE/shared/municipalities';
import VALIDATIONS from '../../../../localizations/config/SE/shared/validations';
import {
  email,
  nationalId,
  dependants,
  homeType,
  homeCost,
  purpose,
  civilStatus,
  employmentType,
  validateEmploymentSinceYear,
  validateEmploymentSinceMonth,
  validateEmploymentToYear,
  validateEmploymentToMonth,
  employerName,
  monthlySalary,
  validatePresence,
  validateNumericality,
  validateZipCodeSE,
} from '../../../../validators';
import phoneNumber from '../../../../validators/SE/phoneNumber';

import textResource from '../../../../utils/text-resources/sambla/SE/messages';
import { mapApplication } from '../../../../mappers/SE/mortgageApplication';

const MUNICIPALITY_OPTIONS = MUNICIPALITIES.map((municipality) => ({
  label: {
    id: municipality.Code,
    defaultMessage: municipality.Name,
    description: municipality.Name,
  },
  value: municipality.Name,
})).sort((a, b) => a.value.localeCompare(b.value));
MUNICIPALITY_OPTIONS.unshift({ label: '', value: null });

const tooltip = (text) => ({
  trigger: 'focus',
  text,
  width: 'fill',
  position: 'right',
});

const applicantFields = (type = 'applicant') => ({
  EMAIL: {
    component: 'ValidatedForm',
    form: 'EmailInput',
    sectionName: type,
    props: {
      name: 'email',
      type: 'email',
      autoComplete: 'email',
      label: type === 'applicant' ? textResource.emailLabel : textResource.coApplicantEmailLabel,
      placeholder: type === 'applicant' ? textResource.emailPlaceholder : textResource.coApplicantEmailPlaceholder,
      tooltip: tooltip(type === 'applicant' ? textResource.emailTooltip : textResource.coApplicantEmailTooltip),
      hasValidateFunction: true,
      validate: email,
    },
  },
  MOBILE_PHONE: {
    component: 'ValidatedForm',
    form: 'TextInput',
    sectionName: type,
    props: {
      name: 'phoneNumber',
      type: 'tel',
      autoComplete: 'phone',
      label: type === 'applicant' ? textResource.phoneNumberLabel : textResource.coApplicantPhoneNumberLabel,
      placeholder: type === 'applicant' ? textResource.phoneNumberPlaceholder : textResource.coApplicantPhoneNumberPlaceholder,
      tooltip: tooltip(type === 'applicant' ? textResource.phoneNumberTooltip : textResource.coApplicantPhoneNumberTooltip),
      hasValidateFunction: true,
      validate: phoneNumber,
    },
  },
  NATIONAL_ID: {
    component: 'ValidatedForm',
    form: 'TextInput',
    sectionName: type,
    props: {
      name: 'nationalId',
      type: 'tel',
      label: type === 'applicant' ? textResource.nationalIdLabel : textResource.coApplicantNationalIdLabel,
      placeholder: type === 'applicant' ? textResource.nationalIdPlaceholder : textResource.coApplicantNationalIdPlaceholder,
      tooltip: tooltip(type === 'applicant' ? textResource.nationalIdTooltip : textResource.coApplicantNationalIdTooltip),
      hasValidateFunction: true,
      validate: nationalId,
    },
  },
  CIVIL_STATUS: {
    component: 'ValidatedForm',
    form: 'ButtonGroup',
    sectionName: type,
    props: {
      name: 'civilStatus',
      label: type === 'applicant' ? textResource.civilStatusLabel : textResource.coApplicantCivilStatusLabel,
      tooltip: tooltip(type === 'applicant' ? textResource.civilStatusTooltip : textResource.coApplicantCivilStatusTooltip),
      buttons: MARITAL_STATUS,
      hasValidateFunction: true,
      validate: civilStatus,
    },
  },
  EMPLOYMENT_TYPE: {
    component: 'ValidatedForm',
    form: 'ButtonGroup',
    sectionName: type,
    props: {
      name: 'employmentType',
      label: type === 'applicant' ? textResource.employmentTypeLabel : textResource.coApplicantEmploymentTypeLabel,
      tooltip: tooltip(type === 'applicant' ? textResource.employmentTypeTooltip : textResource.coApplicantEmploymentTypeTooltip),
      buttons: EMPLOYMENT_TYPES,
      hasValidateFunction: true,
      validate: employmentType,
    },
  },
  EMPLOYMENT_SINCE: {
    YEAR: {
      component: 'EmploymentSinceInputYear',
      sectionName: type,
      props: {
        name: 'employmentSince',
        label: type === 'applicant' ? textResource.employmentSinceYearLabel : textResource.coApplicantEmploymentSinceYearLabel,
        dynamicLabel: [
          {
            key: 'employmentType',
            originalLabel: type === 'applicant' ? textResource.employmentSinceYearLabel : textResource.coApplicantEmploymentSinceYearLabel,
            values: [
              {
                name: 'retired',
                sectionName: type,
                label: type === 'applicant' ? textResource.retiredSinceDynamicLabel : textResource.coApplicantRetiredSinceDynamicLabel,
              },
              {
                name: 'disabilityPension',
                sectionName: type,
                label: type === 'applicant' ? textResource.disabilityPensionSinceDynamicLabel : textResource.coApplicantDisabilityPensionSinceDynamicLabel,
              },
            ],
          },
        ],
        placeholder: type === 'applicant' ? textResource.employmentSinceYearPlaceholder : textResource.coApplicantEmploymentSinceYearPlaceholder,
        tooltip: tooltip(type === 'applicant' ? textResource.employmentSinceYearTooltip : textResource.coApplicantEmploymentSinceYearTooltip),
        hasValidateFunction: true,
        validate: validateEmploymentSinceYear,
      },
    },
    MONTH: {
      component: 'EmploymentSinceInputMonth',
      sectionName: type,
      props: {
        name: 'employmentSinceMonth',
        label: type === 'applicant' ? textResource.employedSinceMonthLabel : textResource.coApplicantEmployedSinceMonthLabel,
        placeholder: type === 'applicant' ? textResource.employedSinceMonthPlaceholder : textResource.coApplicantEmployedSinceMonthPlaceholder,
        tooltip: tooltip(type === 'applicant' ? textResource.employmentSinceMonthTooltip : textResource.coApplicantEmploymentSinceMonthTooltip),
        hasValidateFunction: true,
        validate: validateEmploymentSinceMonth,
        visibleWhen: `return formState.values.${type}.employmentSince > new Date().getFullYear() - 4;`,
      },
    },
  },
  EMPLOYMENT_TO: {
    YEAR: {
      component: 'EmploymentSinceInputYear',
      sectionName: type,
      props: {
        name: 'employmentTo',
        type: 'tel',
        label: type === 'applicant' ? textResource.employedToYearLabel : textResource.coApplicantEmployedToYearLabel,
        placeholder: type === 'applicant' ? textResource.employedToYearPlaceholder : textResource.coApplicantEmployedToYearPlaceholder,
        tooltip: tooltip(type === 'applicant' ? textResource.employedToTooltip : textResource.coApplicantEmployedToTooltip),
        hasValidateFunction: true,
        validate: validateEmploymentToYear,
        visibleWhen: `return formState.values.${type}.employmentType === "project";`,
      },
    },
    MONTH: {
      component: 'EmploymentSinceInputMonth',
      sectionName: type,
      props: {
        name: 'employmentToMonth',
        label: type === 'applicant' ? textResource.employedToMonthLabel : textResource.coApplicantEmployedToMonthLabel,
        placeholder: type === 'applicant' ? textResource.employedToMonthPlaceholder : textResource.coApplicantEmployedToMonthPlaceholder,
        hasValidateFunction: true,
        validate: validateEmploymentToMonth,
        visibleWhen: `return formState.values.${type}.employmentType === "project";`,
      },
    },
  },
  EMPLOYER_NAME: {
    component: 'EmployerName',
    sectionName: type,
    props: {
      name: 'employerName',
      label: type === 'applicant' ? textResource.employerNameLabel : textResource.coApplicantEmployerNameLabel,
      placeholder: type === 'applicant' ? textResource.employerNamePlaceholder : textResource.coApplicantEmployerNamePlaceholder,
      tooltip: tooltip(type === 'applicant' ? textResource.employerNameTooltip : textResource.coApplicantEmployerNameTooltip),
      hasValidateFunction: true,
      validate: employerName,
      visibleWhen: `return ![undefined, "disabilityPension", "retired"].includes(formState.values.${type}.employmentType);`,
    },
  },
  MONTHLY_INCOME_BEFORE_TAX: {
    component: 'ValidatedForm',
    form: 'TextInput',
    sectionName: type,
    props: {
      name: 'monthlyIncomeBeforeTax',
      type: 'tel',
      isNumber: true,
      label: type === 'applicant' ? textResource.monthlySalaryLabel : textResource.coApplicantMonthlySalaryLabel,
      placeholder: type === 'applicant' ? textResource.monthlySalaryPlaceholder : textResource.coApplicantMonthlySalaryPlaceholder,
      tooltip: tooltip(type === 'applicant' ? textResource.monthlySalaryTooltip : textResource.coApplicantMonthlySalaryTooltip),
      suffix: textResource.mortgageInputSuffixSEKPerMonth,
      hasValidateFunction: true,
      validate: monthlySalary,
    },
  },
  NUM_DEPENDANTS: {
    component: 'ValidatedForm',
    form: 'ButtonGroup',
    sectionName: type,
    props: {
      name: 'numDependants',
      label: type === 'applicant' ? textResource.dependantsLabel : textResource.coApplicantDependantsLabel,
      tooltip: tooltip(type === 'applicant' ? textResource.dependantsTooltip : textResource.coApplicantDependantsTooltip),
      buttons: DEPENDANTS,
      buttonLayout: 'compact',
      hasValidateFunction: true,
      validate: dependants,
    },
  },
  HOME_TYPE: {
    component: 'ValidatedForm',
    form: 'ButtonGroup',
    sectionName: type,
    props: {
      name: 'homeType',
      label: type === 'applicant' ? textResource.currentHomeTypeLabel : textResource.coApplicantHomeTypeLabel,
      tooltip: tooltip(type === 'applicant' ? textResource.currentHomeTypeTooltip : textResource.coApplicantHomeTypeTooltip),
      buttons: HOME_TYPES,
      hasValidateFunction: true,
      validate: homeType,
      visibleWhen: 'return formState.values.purpose === "propertyPurchase" || formState.values.property.type === "cottage";',
    },
  },
  HOME_COST: {
    component: 'ValidatedForm',
    form: 'TextInput',
    sectionName: type,
    props: {
      name: 'homeCost',
      type: 'tel',
      isNumber: true,
      label: type === 'applicant' ? textResource.currentHomeCostLabel : textResource.coApplicantHomeCostLabel,
      placeholder: textResource.homeCostPlaceholder,
      tooltip: tooltip(type === 'applicant' ? textResource.currentHomeCostTooltip : textResource.coApplicantHomeCostTooltip),
      suffix: textResource.mortgageInputSuffixSEKPerMonth,
      hasValidateFunction: true,
      validate: homeCost,
      visibleWhen: 'return formState.values.purpose === "propertyPurchase" || formState.values.property.type === "cottage";',
    },
  },
});

const FIELDS = {
  APPLICATION: {
    PURPOSE: {
      component: 'ValidatedForm',
      form: 'ButtonGroup',
      props: {
        name: 'purpose',
        label: textResource.mortgagePurposeLabel,
        buttons: MORTGAGE_LOAN_PURPOSES,
        buttonLayout: 'toggle',
        hasValidateFunction: true,
        validate: purpose,
      },
    },
    ACCEPT_NEWS_LETTER: {
      component: 'AcceptNewsLetter',
      form: 'Checkbox',
      props: {
        name: 'acceptNewsletter',
        text: textResource.samblaPlusText,
        linkText: textResource.samblaPlusReadMore,
        links: {
          SV: 'https://www.sambla.se/om-sambla/sambla-plus/',
          EN: 'https://www.sambla.se/en/sambla-plus/',
        },
      },
    },
  },
  APPLICANT: {
    ...applicantFields(),
  },
  CO_APPLICANT: {
    ...applicantFields('coApplicant'),
  },
  CURRENT_LOANS: {
    MORTGAGE: {
      AMOUNT: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'propertyCurrentLoans',
        props: {
          name: 'amount',
          type: 'tel',
          isNumber: true,
          label: textResource.currentMortgageAmountLabel,
          placeholder: textResource.currentMortgageAmountPlaceholder,
          suffix: textResource.mortgageInputSuffixSEK,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_MORTGAGE_AMOUNT),
          visibleWhen: 'return formState.values.purpose === "consolidateLoans";',
        },
      },
      CREDITOR: {
        component: 'ValidatedForm',
        form: 'Select',
        sectionName: 'propertyCurrentLoans',
        props: {
          name: 'creditor',
          label: textResource.currentMortgageCreditorLabel,
          options: CREDITOR_NAMES,
          hasValidateFunction: true,
          validate: validatePresence(),
          visibleWhen: 'return formState.values.purpose === "consolidateLoans";',
        },
      },
      INTEREST: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'propertyCurrentLoans',
        props: {
          name: 'interest',
          type: 'number',
          isNumber: true,
          disableNumberScrolling: true,
          step: '0.1',
          label: textResource.currentMortgageInterestLabel,
          placeholder: textResource.currentMortgageInterestPlaceholder,
          suffix: textResource.mortgageInputSuffixPercent,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_MORTGAGE_INTEREST),
          visibleWhen: 'return formState.values.purpose === "consolidateLoans";',
        },
      },
      MONTHLY_PAYMENT: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'propertyCurrentLoans',
        props: {
          name: 'monthlyPayment',
          type: 'tel',
          isNumber: true,
          label: textResource.currentMortgageMonthlyPaymentLabel,
          placeholder: textResource.currentMortgageMonthlyPaymentPlaceholder,
          tooltip: tooltip(textResource.currentMortgageMonthlyPaymentTooltip),
          suffix: textResource.mortgageInputSuffixSEKPerMonth,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_MORTGAGE_MONTHLY_PAYMENT),
          visibleWhen: 'return formState.values.purpose === "consolidateLoans";',
        },
      },
    },
    CONSUMER: {
      TOTAL_AMOUNT: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'currentLoans',
        props: {
          name: 'consumerTotalAmount',
          type: 'tel',
          isNumber: true,
          label: textResource.totalConsumerLoansLabel,
          placeholder: textResource.totalConsumerLoansPlaceholder,
          tooltip: tooltip(textResource.totalConsumerLoansTooltip),
          suffix: textResource.mortgageInputSuffixSEK,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_LOANS_CONSUMER_TOTAL),
        },
      },
    },
    CAR: {
      TOTAL_AMOUNT: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'currentLoans',
        props: {
          name: 'carTotalAmount',
          type: 'tel',
          isNumber: true,
          label: textResource.totalCarLoansLabel,
          placeholder: textResource.totalCarLoansPlaceholder,
          tooltip: tooltip(textResource.totalCarLoansTooltip),
          suffix: textResource.mortgageInputSuffixSEK,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_LOANS_CARS_TOTAL),
        },
      },
    },
    CREDIT_CARD: {
      TOTAL_AMOUNT: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'currentLoans',
        props: {
          name: 'creditCardTotalAmount',
          type: 'tel',
          isNumber: true,
          label: textResource.totalCreditCardDebtLabel,
          placeholder: textResource.totalCreditCardDebtPlaceholder,
          tooltip: tooltip(textResource.totalCreditCardDebtTooltip),
          suffix: textResource.mortgageInputSuffixSEK,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_LOANS_CREDIT_CARD_DEBT_TOTAL),
        },
      },
    },
    OTHER: {
      TOTAL_AMOUNT: {
        component: 'ValidatedForm',
        form: 'TextInput',
        sectionName: 'currentLoans',
        props: {
          name: 'otherTotalAmount',
          type: 'tel',
          isNumber: true,
          label: textResource.totalOtherLoansLabel,
          placeholder: textResource.totalOtherLoansPlaceholder,
          tooltip: tooltip(textResource.totalOtherLoansTooltip),
          suffix: textResource.mortgageInputSuffixSEK,
          hasValidateFunction: true,
          validate: validateNumericality(VALIDATIONS.CURRENT_LOANS_OTHER_TOTAL),
        },
      },
    },
  },
  PROPERTY: {
    TYPE: {
      component: 'ValidatedForm',
      form: 'ButtonGroup',
      sectionName: 'property',
      props: {
        name: 'type',
        label: textResource.propertyTypeLabel,
        buttons: PROPERTY_TYPES,
        hasValidateFunction: true,
        validate: validatePresence(),
      },
    },
    ESTIMATED_VALUE: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'estimatedValue',
        type: 'tel',
        isNumber: true,
        label: textResource.propertyEstimatedValueLabel,
        placeholder: textResource.propertyEstimatedValuePlaceholder,
        suffix: textResource.mortgageInputSuffixSEK,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.PROPERTY_ESTIMATED_VALUE),
      },
    },
    DOWN_PAYMENT: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'downPayment',
        type: 'tel',
        isNumber: true,
        label: textResource.propertyDownPaymentLabel,
        placeholder: textResource.propertyDownPaymentPlaceholder,
        tooltip: tooltip(textResource.propertyDownPaymentTooltip),
        hasValidateFunction: true,
        suffix: textResource.mortgageInputSuffixSEK,
        validate: validateNumericality(VALIDATIONS.PROPERTY_DOWN_PAYMENT),
        visibleWhen: 'return formState.values.purpose === "propertyPurchase";',
      },
    },
    MONTHLY_FEE: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'monthlyFee',
        type: 'tel',
        isNumber: true,
        label: textResource.propertyMonthlyFeeLabel,
        placeholder: textResource.propertyMonthlyFeePlaceholder,
        tooltip: tooltip(textResource.propertyMonthlyFeeTooltip),
        suffix: textResource.mortgageInputSuffixSEKPerMonth,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.PROPERTY_MONTHLY_FEE),
        visibleWhen: 'return ["condo"].includes(formState.values.property.type);',
      },
    },
    OPERATING_COST: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'monthlyFee',
        type: 'tel',
        isNumber: true,
        label: textResource.propertyOperatingCostLabel,
        placeholder: textResource.propertyOperatingCostPlaceholder,
        tooltip: tooltip(textResource.propertyOperatingCostTooltip),
        suffix: textResource.mortgageInputSuffixSEKPerMonth,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.PROPERTY_OPERATING_COST),
        visibleWhen: 'return ["villa", "cottage"].includes(formState.values.property.type);',
      },
    },
    LIVING_SPACE: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'livingSpace',
        type: 'tel',
        isNumber: true,
        label: textResource.propertyLivingSpaceLabel,
        placeholder: textResource.propertyLivingSpacePlaceholder,
        suffix: textResource.mortgageInputSuffixSquareMetres,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.PROPERTY_LIVING_SPACE),
      },
    },
    ADDRESS_AUTOCOMPLETE: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'address',
        label: textResource.propertyAddressLabel,
        placeholder: textResource.propertyAddressPlaceholder,
        hasValidateFunction: true,
        validate: validatePresence(),
      },
    },
    ZIP_CODE: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'zipCode',
        autoComplete: 'off',
        label: textResource.propertyZipCodeLabel,
        placeholder: textResource.propertyZipCodePlaceholder,
        hasValidateFunction: true,
        validate: validateZipCodeSE({ required: true }),
      },
    },
    CITY_NAME: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'cityName',
        autoComplete: 'off',
        label: textResource.propertyCityNameLabel,
        placeholder: textResource.propertyCityNamePlaceholder,
        hasValidateFunction: true,
        validate: validatePresence(),
      },
    },
    MUNICIPALITY_NAME: {
      component: 'ValidatedForm',
      form: 'Select',
      sectionName: 'property',
      props: {
        name: 'municipalityName',
        label: textResource.propertyMunicipalityNameLabel,
        placeholder: textResource.propertyMunicipalityNamePlaceholder,
        tooltip: tooltip(textResource.propertyMunicipalityNameTooltip),
        options: MUNICIPALITY_OPTIONS,
        hasValidateFunction: true,
        validate: validatePresence(),
      },
    },
    NUM_ROOMS: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'property',
      props: {
        name: 'numberOfRooms',
        type: 'tel',
        isNumber: true,
        label: textResource.propertyNumberOfRoomsLabel,
        placeholder: textResource.propertyNumberOfRoomsPlaceholder,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.PROPERTY_NUM_ROOMS),
      },
    },
  },
  CARS: {
    TOTAL_OWNED: {
      component: 'ValidatedForm',
      form: 'ButtonGroup',
      sectionName: 'cars',
      props: {
        name: 'ownedTotalAmount',
        label: textResource.carsOwnedLabel,
        buttons: CARS,
        buttonLayout: 'compact',
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.CARS_OWNED_AMOUNT),
      },
    },
    TOTAL_OWNED_MONTHLY_COST: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'cars',
      props: {
        name: 'ownedTotalMonthlyCost',
        type: 'tel',
        isNumber: true,
        label: textResource.carsOwnedMonthlyCostLabel,
        placeholder: textResource.carsOwnedMonthlyCostPlaceholder,
        tooltip: tooltip(textResource.carsOwnedMonthlyCostTooltip),
        suffix: textResource.mortgageInputSuffixSEKPerMonth,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.CARS_OWNED_MONTHLY_COST),
        visibleWhen: 'return formState.values.cars.ownedTotalAmount > 0;',
      },
    },
    TOTAL_LEASED: {
      component: 'ValidatedForm',
      form: 'ButtonGroup',
      sectionName: 'cars',
      props: {
        name: 'leasedTotalAmount',
        label: textResource.carsLeasedLabel,
        buttons: CARS,
        buttonLayout: 'compact',
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.CARS_LEASED_AMOUNT),
      },
    },
    TOTAL_LEASED_MONTHLY_COST: {
      component: 'ValidatedForm',
      form: 'TextInput',
      sectionName: 'cars',
      props: {
        name: 'leasedTotalMonthlyCost',
        type: 'tel',
        isNumber: true,
        label: textResource.carsLeasedMonthlyCostLabel,
        placeholder: textResource.carsLeasedMonthlyCostPlaceholder,
        tooltip: tooltip(textResource.carsLeasedMonthlyCostTooltip),
        suffix: textResource.mortgageInputSuffixSEKPerMonth,
        hasValidateFunction: true,
        validate: validateNumericality(VALIDATIONS.CARS_LEASED_MONTHLY_COST),
        visibleWhen: 'return formState.values.cars.leasedTotalAmount > 0;',
      },
    },
  },
};

const COMPONENTS = {
  nextButton: (destinationIndex, props = {}) => ({
    component: 'Teleporter',
    props: {
      label: textResource.nextSlide,
      ctaBtnStyle: true,
      destinationIndex,
      sendAbandonCartEvent: destinationIndex === 1,
      formType: FormTypes.MORTGAGE_LOAN,
      ...props,
    },
  }),
  submitButton: (props = {}) => ({
    component: 'SubmitButton',
    props: {
      label: textResource.formSubmit,
      ctaBtnStyle: true,
      messages: {
        uncaughtSubmitError: textResource.submitErrorUncaught,
        generalSubmitError: textResource.submitErrorGeneral,
      },
      ...props,
    },
  }),
  break: (shade) => ({
    component: 'Break',
    props: { shade },
  }),
};

const promptToAddCoApplicant = [
  {
    component: 'FormTextBlock',
    props: {
      text: textResource.addCoApplicantLabel,
      tooltip: tooltip(textResource.employerPhoneTooltip),
      transparent: true,
      header: true,
    },
  },
  {
    component: 'ValidatedForm',
    form: 'ButtonGroup',
    props: {
      label: textResource.addCoApplicantDesc,
      name: 'hasCoApplicant',
      rightPadding: true,
      buttons: [
        {
          label: textResource.mergeLoanYes,
          value: 'true',
        },
        {
          label: textResource.mergeLoanNo,
          value: 'undefined',
        },
      ],
      tooltip: tooltip(textResource.addCoApplicantTooltip),
    },
  },
];

const data = {
  general: {
    mapper: mapApplication,
  },
  links: {
    quickReapply: '/returning-customer/',
    quickReapplyEng: '/en/returning-customer/',
    userAgreement: 'https://www.sambla.se/om-sambla/anvandarvillkor/',
    userAgreementEng: 'https://www.sambla.se/en/user-agreement/',
    dataPolicy: 'https://www.sambla.se/om-sambla/dataskyddspolicy/',
    dataPolicyEng: 'https://www.sambla.se/en/data-protection-policy/',
  },
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      showBackButton: false,
      fields: [
        FIELDS.APPLICATION.PURPOSE,
        FIELDS.APPLICANT.EMAIL,
        FIELDS.APPLICANT.MOBILE_PHONE,
        FIELDS.APPLICATION.ACCEPT_NEWS_LETTER,
        COMPONENTS.nextButton(1),
      ],
    },
    {
      title: textResource.mortgageSlideTitleProperty,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        FIELDS.PROPERTY.TYPE,
        FIELDS.PROPERTY.ESTIMATED_VALUE,
        FIELDS.PROPERTY.DOWN_PAYMENT,
        FIELDS.CURRENT_LOANS.MORTGAGE.AMOUNT,
        FIELDS.CURRENT_LOANS.MORTGAGE.CREDITOR,
        FIELDS.CURRENT_LOANS.MORTGAGE.INTEREST,
        FIELDS.CURRENT_LOANS.MORTGAGE.MONTHLY_PAYMENT,
        COMPONENTS.break('up'),
        FIELDS.PROPERTY.ADDRESS_AUTOCOMPLETE,
        FIELDS.PROPERTY.ZIP_CODE,
        FIELDS.PROPERTY.CITY_NAME,
        FIELDS.PROPERTY.MUNICIPALITY_NAME,
        FIELDS.PROPERTY.MONTHLY_FEE,
        FIELDS.PROPERTY.OPERATING_COST,
        FIELDS.PROPERTY.NUM_ROOMS,
        FIELDS.PROPERTY.LIVING_SPACE,
        COMPONENTS.nextButton(2),
      ],
    },
    {
      title: textResource.mortgageSlideTitleHousehold,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        FIELDS.CARS.TOTAL_OWNED,
        FIELDS.CARS.TOTAL_OWNED_MONTHLY_COST,
        FIELDS.CARS.TOTAL_LEASED,
        FIELDS.CARS.TOTAL_LEASED_MONTHLY_COST,
        COMPONENTS.nextButton(3),
      ],
    },
    {
      title: textResource.mortgageSlideTitleFinances,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        FIELDS.CURRENT_LOANS.CONSUMER.TOTAL_AMOUNT,
        FIELDS.CURRENT_LOANS.CAR.TOTAL_AMOUNT,
        FIELDS.CURRENT_LOANS.CREDIT_CARD.TOTAL_AMOUNT,
        FIELDS.CURRENT_LOANS.OTHER.TOTAL_AMOUNT,
        COMPONENTS.nextButton(4),
      ],
    },
    {
      title: textResource.mortgageSlideTitleApplicant,
      coApplicantSlide: false,
      showBackButton: true,
      fields: [
        FIELDS.APPLICANT.CIVIL_STATUS,
        FIELDS.APPLICANT.NUM_DEPENDANTS,
        FIELDS.APPLICANT.HOME_TYPE,
        FIELDS.APPLICANT.HOME_COST,
        FIELDS.APPLICANT.NATIONAL_ID,
        COMPONENTS.break('up'),
        FIELDS.APPLICANT.EMPLOYMENT_TYPE,
        FIELDS.APPLICANT.EMPLOYMENT_SINCE.YEAR,
        FIELDS.APPLICANT.EMPLOYMENT_SINCE.MONTH,
        FIELDS.APPLICANT.EMPLOYMENT_TO.YEAR,
        FIELDS.APPLICANT.EMPLOYMENT_TO.MONTH,
        FIELDS.APPLICANT.EMPLOYER_NAME,
        FIELDS.APPLICANT.MONTHLY_INCOME_BEFORE_TAX,
        COMPONENTS.break('up'),
        ...promptToAddCoApplicant,
        COMPONENTS.break('down'),
        COMPONENTS.nextButton(5, { visibleWhen: 'return formState.values.hasCoApplicant === true' }),
        COMPONENTS.submitButton({ visibleWhen: 'return formState.values.hasCoApplicant !== true' }),
      ],
    },
    {
      title: textResource.mortgageSlideTitleCoApplicant,
      coApplicantSlide: true,
      showBackButton: true,
      fields: [
        FIELDS.CO_APPLICANT.EMAIL,
        FIELDS.CO_APPLICANT.MOBILE_PHONE,
        FIELDS.CO_APPLICANT.NATIONAL_ID,
        FIELDS.CO_APPLICANT.CIVIL_STATUS,
        FIELDS.CO_APPLICANT.NUM_DEPENDANTS,
        FIELDS.CO_APPLICANT.HOME_TYPE,
        FIELDS.CO_APPLICANT.HOME_COST,
        COMPONENTS.break('up'),
        FIELDS.CO_APPLICANT.EMPLOYMENT_TYPE,
        FIELDS.CO_APPLICANT.EMPLOYMENT_SINCE.YEAR,
        FIELDS.CO_APPLICANT.EMPLOYMENT_SINCE.MONTH,
        FIELDS.CO_APPLICANT.EMPLOYMENT_TO.YEAR,
        FIELDS.CO_APPLICANT.EMPLOYMENT_TO.MONTH,
        FIELDS.CO_APPLICANT.EMPLOYER_NAME,
        FIELDS.CO_APPLICANT.MONTHLY_INCOME_BEFORE_TAX,
        COMPONENTS.submitButton(),
      ],
    },
  ],
};

export default data;
