export default (nationalId) => {
  const arr = nationalId.split('');
  if (arr.includes('-')) {
    const index = arr.indexOf('-');
    arr.splice(index, 1);
  }
  if (arr.length < 12) {
    if (parseInt(arr[0], 10) < 9) {
      arr.unshift('20');
    } else {
      arr.unshift('19');
    }
  }

  return arr.join('');
};
