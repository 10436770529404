import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import messageDescriptor from '../../../../../js/schemas/message-descriptor';
import intlShape from '../../../../../js/schemas/intl';
import tooltipSchema from '../../../../../js/schemas/tooltip';
import tooltipToObject from '../../../../../js/helpers/tooltipToObject';

import Tooltips from '../../../../utils/tooltips';

const Wrapper = styled.div`
  && {
    margin: ${(props) => props.margin || '0 0 1rem 0'};
    background: none;
    border: none;
    position: relative;
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0 0 0.5rem;
  }
`;

const Label = styled.label`
  && {
    margin: 0;
    padding: 0;
    position: relative;
    font-size: 0.9rem;
    z-index: 1;
  }
`;

const Input = styled.div`
  && {
    align-items: center;
    background: var(--off-white);
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: text;
    display: flex;
    height: 3em;
    justify-content: space-between;
    overflow: hidden;

    ${(props) => props.placeholder && css`
      border: 2px solid var(--dark-teal-20);
    `}

    // validated
    ${(props) => props.valid && props.validated && css`
      border: 2px solid var(--teal);
    `}

    // not validated
    ${(props) => (props.validated && props.valid === false) && css`
      border:  2px solid var(--red);
    `}
  }
`;

const InputSelect = styled.select`
  && {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--off-white);
    background-image: url(https://se-sambla-production-cms-uploadbucket-13gmwhjtytv7f.s3.amazonaws.com/uploads/2023/03/SamblaSelectSVG.svg);
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 0;
    color: var(--dark-teal);
    cursor: pointer;
    -ms-flex: 1 1;
    flex: 1 1;
    height: 100%;
    outline: none;
    padding: 0 0.5rem;
    width: 100%;

    ${(props) => props.placeholder && css`
    color: var--placeholder-grey);
    `}

    ${(props) => props.valid && css`
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='7'%3E%3Cpath fill='%231bac5e' fill-rule='evenodd' d='M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z'/%3E%3C/svg%3E");
    `}

    ${(props) => props.valid === false && props.validated && css`
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='7'%3E%3Cpath fill='%23b81c4f' fill-rule='evenodd' d='M5.732 6.56c.58.59 1.52.592 2.101 0l5.399-5.491C13.812.479 13.619 0 12.79 0H.776C-.05 0-.248.477.333 1.069L5.732 6.56z'/%3E%3C/svg%3E");
    `}
  }
`;

const Select = ({
  id,
  name,
  compact,
  labelInside,
  label,
  multiple,
  validated,
  valid,
  className,
  options,
  tooltip,
  sorted,
  tooltipLink,
  intl: { formatMessage },
  override,
  margin,
  placeholder,
  ...rest
}) => {
  const selectId = id || `select--${name || ''}--${label.replace(/\s/, '-')}`;

  const getLabel = (option) => {
    if (typeof option.label === 'string') return option.label;

    return formatMessage(option.label);
  };

  const getKey = (option) => {
    if (typeof option.label === 'string') return option.label;

    return option.label.id;
  };

  const selectOption = (option) => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.disabled}
    >
      {getLabel(option)}
    </option>
  );

  switch (sorted) {
    case 'A-Z':
      options.sort((a, b) => {
        if (formatMessage(a.label) < formatMessage(b.label)) { return -1; }
        if (formatMessage(a.label) > formatMessage(b.label)) { return 1; }
        return 0;
      });
      // add placeholder back on top
      // eslint-disable-next-line no-nested-ternary
      options.sort((x, y) => (x.value === '' ? -1 : y.value === '' ? 1 : 0));
      break;
    default:
  }

  const selectOptions = options.map((option) => (
    option.subOptions
      ? (
        <optgroup
          key={getKey(option)}
          label={getLabel(option)}
        >
          {option.subOptions.map((subOption) => selectOption(subOption))}
        </optgroup>
      )
      : selectOption(option)
  ));

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') { tooltipObject.text = formatMessage(tooltip.text); }

  return (
    <Wrapper
      margin={margin}
    >
      {label && label.id && (
      <LabelWrapper>
        <Label
          htmlFor={selectId}
        >
          {formatMessage(label)}
        </Label>
        { tooltipObject.text
          && (
            <Tooltips tooltipObject={tooltipObject} />
          )}
      </LabelWrapper>
      )}

      <Input
        validated={validated}
        valid={valid}
        placeholder={placeholder}
      >
        <InputSelect
          placeholder={placeholder}
          valid={valid}
          validated={validated}
          id={selectId}
          name={name}
          multiple={multiple}
          {...rest}
        >
          {selectOptions}
        </InputSelect>
      </Input>

    </Wrapper>
  );
};

const optionSchema = PropTypes.shape({
  label: PropTypes.oneOfType([
    PropTypes.string,
    messageDescriptor,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  disabled: PropTypes.bool,
});

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.string,
  compact: PropTypes.bool,
  labelInside: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  sorted: PropTypes.string,
  validated: PropTypes.bool,
  valid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      optionSchema,
      PropTypes.shape({
        subOptions: PropTypes.arrayOf(optionSchema),
      }),
    ]),
  ),
  className: PropTypes.string,
  tooltip: tooltipSchema,
  tooltipLink: PropTypes.string,
  intl: intlShape.isRequired,
  placeholder: PropTypes.shape(),
  override: PropTypes.shape({
    input: PropTypes.shape({
      labelClassName: PropTypes.string,
    }),
  }),
};

Select.defaultProps = {
  id: null,
  name: null,
  compact: false,
  labelInside: false,
  label: '',
  margin: '',
  sorted: '',
  multiple: false,
  validated: false,
  valid: null,
  options: [],
  className: '',
  tooltip: '',
  tooltipLink: '',
  placeholder: {},
  override: {
    input: {},
  },
};

export default injectIntl(Select);
