import textResource from '../../../../utils/text-resources/sambla/SE/messages';

const YEARSELECT = [
  {
    label: textResource.yearONE,
    value: '1',
  },
  {
    label: textResource.yearTWO,
    value: '2',
  },
  {
    label: textResource.yearTHREE,
    value: '3',
  },
  {
    label: textResource.yearFOUR,
    value: '4',
  },
  {
    label: textResource.yearFIVE,
    value: '5',
  },
  {
    label: textResource.yearSIX,
    value: '6',
  },
  {
    label: textResource.yearSEVEN,
    value: '7',
  },
  {
    label: textResource.yearEIGHT,
    value: '8',
  },
  {
    label: textResource.yearNINE,
    value: '9',
  },
  {
    label: textResource.yearTEN,
    value: '10',
  },
  {
    label: textResource.yearELEVEN,
    value: '11',
  },
  {
    label: textResource.yearTWELVE,
    value: '12',
  },
  {
    label: textResource.yearTHIRTEEN,
    value: '13',
  },
  {
    label: textResource.yearFOURTEEN,
    value: '14',
  },
  {
    label: textResource.yearFIVETEEN,
    value: '15',
  },
  {
    label: textResource.yearSIXTEEN,
    value: '16',
  },
  {
    label: textResource.yearSEVENTEEN,
    value: '17',
  },
  {
    label: textResource.yearEIGHTTEEN,
    value: '18',
  },
  {
    label: textResource.yearNINETEEN,
    value: '19',
  },
  {
    label: textResource.yearTWENTY,
    value: '20',
  },
];
export default YEARSELECT;
