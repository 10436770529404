export default {
  CURRENT_MORTGAGE_AMOUNT: {
    required: true,
    isCurrency: true,
    min: 1,
    max: 20000000,
  },
  CURRENT_MORTGAGE_MONTHLY_PAYMENT: {
    required: true,
    isCurrency: true,
  },
  CURRENT_MORTGAGE_INTEREST: {
    required: true,
    max: 10,
  },
  CURRENT_LOANS_CONSUMER_TOTAL: {
    isCurrency: true,
    max: 1000000,
  },
  CURRENT_LOANS_CARS_TOTAL: {
    isCurrency: true,
    max: 1000000,
  },
  CURRENT_LOANS_CREDIT_CARD_DEBT_TOTAL: {
    isCurrency: true,
    max: 1000000,
  },
  CURRENT_LOANS_OTHER_TOTAL: {
    isCurrency: true,
    max: 1000000,
  },
  PROPERTY_ESTIMATED_VALUE: {
    required: true,
    isCurrency: true,
    min: 1,
    max: 20000000,
  },
  PROPERTY_MONTHLY_FEE: {
    required: true,
    isCurrency: true,
    max: 50000,
  },
  PROPERTY_DOWN_PAYMENT: {
    required: true,
    isCurrency: true,
  },
  PROPERTY_OPERATING_COST: {
    required: true,
    isCurrency: true,
    max: 50000,
  },
  PROPERTY_LIVING_SPACE: {
    required: true,
    min: 1,
  },
  PROPERTY_NUM_ROOMS: {
    required: true,
    min: 1,
  },
  CARS_OWNED_AMOUNT: {
    required: true,
    max: 5,
  },
  CARS_OWNED_MONTHLY_COST: {
    required: true,
    isCurrency: true,
    max: 30000,
  },
  CARS_LEASED_AMOUNT: {
    required: true,
    max: 5,
  },
  CARS_LEASED_MONTHLY_COST: {
    required: true,
    isCurrency: true,
    max: 30000,
  },
};
