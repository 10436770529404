import { valid, invalid } from '../../utils/validators/resolutions';
import messages from '../../utils/text-resources/sambla/SE/messages';

const zipCode = ({ required } = {}) => (value) => {
  const strippedValue = (value || '').replaceAll(' ', '');

  if (required && !/\S+/.test(strippedValue)) {
    return invalid(messages.zipCodeIsRequired);
  }
  // both "xxx xx" and "xxxxx" are valid
  if (strippedValue !== '' && !/^(\d{3} \d{2})$|^(\d{5})$/.test(value)) {
    return invalid(messages.zipCodeMustMatchFormatSE);
  }

  return valid();
};

export default zipCode;
