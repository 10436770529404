export default {
  ctaButtonBackground: 'var(--cta-color)',
  buttonBorderRadius: '12rem',
  buttonBorderRadiusLead: '12rem',
  buttonPadding: '2rem',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  opacity: '0.4',
  transform: 'uppercase',
  ctaButtonColor: 'var(--white)',
};
