import format from 'date-fns/format';
import validators from '@advisa/common-validators';
import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import {
  isEmployed,
  isTempEmployed,
} from './applicantFunctions';
import MUNICIPALITIES from '../../localizations/config/SE/shared/municipalities';
import mappers from '../../enums/index';
import enums from '../../enums/shared';

const normalizeEmail = (email) => {
  const result = validators.email.parse(email);
  return result.formatted;
};

const normalizeNationalId = (nationalId) => {
  const result = validators.nationalId.parse(nationalId, 'se');
  return result.nationalId.long;
};

const normalizePhone = (phone) => {
  const result = validators.phone.parse(phone, 'se');
  return result.phone;
};

const formatDate = (year, month) => format(new Date(year, month || 0), 'yyyy-MM-dd\'T\'HH:mm:ss');

const formatInt = (number) => {
  const formatted = parseInt(number, 10);
  return Number.isNaN(formatted) ? null : formatted;
};

const formatCurrency = (number) => formatInt(stripDelimiter(number));

const formatDecimal = (number) => {
  const formatted = parseFloat(number);
  return Number.isNaN(formatted) ? null : formatted;
};

const formatZipCode = (zipCode) => {
  const stripped = zipCode.replaceAll(' ', '');
  return `${stripped.slice(0, 3)} ${stripped.slice(3, 5)}`;
};

export const mapHomeType = (homeType) => {
  switch (homeType) {
    case 'rentingApartment':
      return enums.homeType.RENTED_APARTMENT;
    case 'rentingRoom':
      return enums.homeType.RENTED_ROOM;
    case 'houseOrTownhouse':
      return enums.homeType.VILLA;
    case 'condominium':
      return enums.homeType.CONDOMINIUM;
    default:
      return enums.homeType.OTHER;
  }
};

const mapApplicant = (applicant) => {
  if (!applicant) {
    return null;
  }

  const output = {
    email: normalizeEmail(applicant.email),
    normalizedEmail: normalizeEmail(applicant.email),
    mobilePhone: normalizePhone(applicant.phoneNumber),
    nationalId: normalizeNationalId(applicant.nationalId),
    civilStatus: mappers.civilStatus[applicant.civilStatus],
    employmentType: mappers.employmentType[applicant.employmentType],
    employerName: (isEmployed(applicant) && applicant.employerName) || null,
    employmentSince: applicant.employmentSince
      ? formatDate(applicant.employmentSince, applicant.employmentSinceMonth)
      : null,
    employmentTo: isTempEmployed(applicant) && applicant.employmentTo
      ? formatDate(applicant.employmentTo, applicant.employmentToMonth)
      : null,
    monthlyIncomeBeforeTax: formatCurrency(applicant.monthlyIncomeBeforeTax),
    numDependants: formatInt(applicant.numDependants),
    homeCost: formatCurrency(applicant.homeCost) || 0,
    homeType: mapHomeType(applicant.homeType),
  };

  const isSelfEmployed = [
    enums.employmentType.OWN_COMPANY,
    enums.employmentType.SELF_EMPLOYED,
  ].includes(output.employmentType);

  if (!output.employerPhone && isSelfEmployed) {
    output.employerPhone = output.mobilePhone;
  }

  return output;
};

const createCurrentLoanDraftsFromFormData = ({
  consumerTotalAmount,
  carTotalAmount,
  creditCardTotalAmount,
  otherTotalAmount,
}) => {
  const currentLoans = [];
  const currentLoanDefaults = {
    monthlyPayment: null,
    transfer: true,
    interest: null,
    administrationFee: null,
    insuranceCost: null,
    creditor: null,
    share: null,
  };

  if (consumerTotalAmount) {
    currentLoans.push({
      ...currentLoanDefaults,
      type: enums.currentLoanType.CONSUMER,
      amount: consumerTotalAmount,
    });
  }
  if (carTotalAmount) {
    currentLoans.push({
      ...currentLoanDefaults,
      type: enums.currentLoanType.CAR,
      amount: carTotalAmount,
    });
  }
  if (creditCardTotalAmount) {
    currentLoans.push({
      ...currentLoanDefaults,
      transfer: false,
      type: enums.currentLoanType.CREDIT_CARD,
      amount: creditCardTotalAmount,
    });
  }
  if (otherTotalAmount) {
    currentLoans.push({
      ...currentLoanDefaults,
      type: enums.currentLoanType.OTHER,
      amount: otherTotalAmount,
    });
  }
  return currentLoans;
};

const createCarDraftsFromFormData = ({
  ownedTotalAmount,
  leasedTotalAmount,
  ownedTotalMonthlyCost,
  leasedTotalMonthlyCost,
}) => {
  const cars = [];

  if (leasedTotalAmount > 0) {
    cars.push({
      leased: true,
      monthlyCost: leasedTotalMonthlyCost,
    });

    for (let i = 1; i < leasedTotalAmount; i += 1) {
      cars.push({
        leased: true,
        monthlyCost: 0,
      });
    }
  }
  if (ownedTotalAmount > 0) {
    cars.push({
      leased: false,
      monthlyCost: ownedTotalMonthlyCost,
    });

    for (let i = 1; i < ownedTotalAmount; i += 1) {
      cars.push({
        leased: false,
        monthlyCost: 0,
      });
    }
  }
  return cars;
};

const createPropertyLoanDraftsFromFormData = (propertyCurrentLoans) => ({
  amount: formatCurrency(propertyCurrentLoans.amount),
  monthlyPayment: formatCurrency(propertyCurrentLoans.monthlyPayment),
  creditor: propertyCurrentLoans.creditor,
  interest: formatDecimal(propertyCurrentLoans.interest),
  type: enums.currentLoanType.MORTGAGE,
  transfer: true,
  administrationFee: null,
  insuranceCost: null,
  share: null,
});

const createPropertyDraftsFromFormData = (formDataProperty, purpose) => {
  if (!formDataProperty) {
    return [];
  }

  return {
    type: formDataProperty.type,
    estimatedValue: formatCurrency(formDataProperty.estimatedValue),
    downPayment: purpose === 'propertyPurchase'
      ? formatCurrency(formDataProperty.downPayment)
      : 0,
    monthlyFee: formatCurrency(formDataProperty.monthlyFee),
    livingSpace: formatInt(formDataProperty.livingSpace),
    address: formDataProperty.address,
    zipCode: formatZipCode(formDataProperty.zipCode),
    cityName: formDataProperty.cityName,
    municipalityName: formDataProperty.municipalityName,
    municipalityCode: MUNICIPALITIES.find((m) => m.Name === formDataProperty.municipalityName).Code,
    referenceCode: formDataProperty.referenceCode || 'n/a',
    associationId: formDataProperty.associationId || 'n/a',
    associationName: formDataProperty.associationName || 'n/a',
    numRooms: formatInt(formDataProperty.numberOfRooms),
    sourceType: 'bought',
  };
};

const calculateTotalLoan = ({ purpose, currentLoans, property }) => {
  let sum = currentLoans.filter(({ transfer }) => transfer)
    .reduce((total, { amount }) => (amount || 0) + total, 0);

  switch (purpose) {
    case enums.purpose.COMBINING_LOANS:
      sum += property.currentLoans.reduce((total, { amount }) => (amount || 0) + total, 0);
      return sum;
    case enums.purpose.PROPERTY_PURCHASE:
      return sum + (property.estimatedValue || 0) - (property.downPayment || 0);
    default:
      return sum || null;
  }
};

export const mapApplication = (formData) => {
  const consumerTotalAmount = formatCurrency(formData.currentLoans?.consumerTotalAmount);
  const carTotalAmount = formatCurrency(formData.currentLoans?.carTotalAmount);
  const creditCardTotalAmount = formatCurrency(formData.currentLoans?.creditCardTotalAmount);
  const otherTotalAmount = formatCurrency(formData.currentLoans?.otherTotalAmount);

  const cars = {
    ownedTotalAmount: formatInt(formData.cars.ownedTotalAmount),
    leasedTotalAmount: formatInt(formData.cars.leasedTotalAmount),
    ownedTotalMonthlyCost: formatCurrency(formData.cars.ownedTotalMonthlyCost),
    leasedTotalMonthlyCost: formatCurrency(formData.cars.leasedTotalMonthlyCost),
  };

  const application = {
    inputSource: formData.source,
    desiredRepaymentTime: 480,
    desiredMortgageAmortizationAmount: null,
    purpose: mappers.purpose[formData.purpose],
    applicationType: 'MORTGAGE',
    creditCheckConsent: null,
    scheduledCallDate: null,
    oldLoanRepaymentTime: null,
    acceptNewsletter: !!formData.acceptNewsletter,
    applicant: {
      ...mapApplicant(formData.applicant),
      properties: [{
        ...createPropertyDraftsFromFormData(formData.property, formData.purpose),
        currentLoans: mappers.purpose[formData.purpose] === enums.purpose.PROPERTY_PURCHASE
          ? []
          : [
            createPropertyLoanDraftsFromFormData(formData.propertyCurrentLoans),
          ],
      }],
      cars: createCarDraftsFromFormData(cars),
      currentLoans: createCurrentLoanDraftsFromFormData(
        {
          consumerTotalAmount,
          carTotalAmount,
          creditCardTotalAmount,
          otherTotalAmount,
        },
      ),
    },
    secondaryApplicant: formData.coApplicant
      ? {
        ...mapApplicant(formData.coApplicant),
        currentLoans: [],
      }
      : null,
  };

  if (enums.purpose.COMBINING_LOANS === application.purpose
    && [enums.propertyType.condo, enums.propertyType.villa].includes(formData.property.type)) {
    if (formData.property.type === enums.propertyType.condo) {
      application.applicant.homeType = enums.homeType.CONDOMINIUM;
    }

    if (formData.property.type === enums.propertyType.villa) {
      application.applicant.homeType = enums.homeType.VILLA;
    }

    if (application.secondaryApplicant) {
      application.secondaryApplicant.homeType = application.applicant.homeType;
    }

    application.applicant.homeCost = application.applicant.properties[0].currentLoans
      .reduce((sum, { monthlyPayment }) => (sum + monthlyPayment),
        application.applicant.properties[0].monthlyFee);
  }

  application.totalLoan = calculateTotalLoan({
    purpose: mappers.purpose[formData.purpose],
    currentLoans: application.applicant.currentLoans,
    property: application.applicant.properties[0],
  });

  return application;
};

export default { mapApplication };
